.article-side {
    display: flex
}

.article {
    font-family: Helvetica, sans-serif;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
    .article-side {
        display: block;
    }
}

@media only screen and (min-width: 601px) {
    .article {
        min-height: 500px;
    }
}

.side-menu__link {
    display: block;
    margin: 1rem 0;
}

main {
    padding: 1rem 0
}

h1, h2, nav {
    font-family: Helvetica, sans-serif;
}

h1 {
    font-size: 36px;
}
h2 {
    font-size: 28px;
}
h3 {
    font-weight: lighter;
    color: #391902;
}
nav {
    font-weight: bold;
}
a {
    text-decoration: none;
}
a:visited {
    color: #322d1e;
}


footer {
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
}

article {
    padding-left: 1rem;
    font-family: Helvetica, sans-serif;
}

.correspondent {
    color: #CC3333;
    font-weight: bold;
}

.respondent {
    color: black;
    font-weight: bold;
}

.top-menu {
    background-color: Cornsilk;
    border-bottom: solid 1px;
    border-top: solid 1px;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.bottom-menu {
    background-color: Cornsilk;
    border-bottom: solid 1px;
    border-top: solid 1px;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.side-menu {
    background-color: Ivory;
    border-right: solid 1px;
    padding: 1rem;
}

.signature {
    width: 300px;
}